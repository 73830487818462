body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .fa {
  color: #222;
} */

.btn {
  padding: 10px !important;
  border-radius: 4px !important;
}

.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.cursor-pointer {
  cursor: pointer !important;
}

.font-bold {
  font-family: "Lato-Bold" !important;
}

.font-light {
  font-family: "Lato-Light" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-8 {
  font-size: 0.5rem !important;
}

.font-9 {
  font-size: 0.563rem !important;
}

.font-10 {
  font-size: 0.625rem !important;
}

.font-11 {
  font-size: 0.6875rem !important;
}

.font-12 {
  font-size: 0.75rem !important;
}

.font-13 {
  font-size: 0.8125rem !important;
}

.font-14 {
  font-size: 0.875rem !important;
}

.font-15 {
  font-size: 0.9375rem !important;
}

.font-16 {
  font-size: 1rem !important;
}

.font-18 {
  font-size: 1.125rem !important;
}

.font-20 {
  font-size: 1.25rem !important;
}

.font-24 {
  font-size: 1.5rem !important;
}
.font-28 {
  font-size: 1.7rem !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 2 !important;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

/* Color */
.text-blue {
  color: #2196f3 !important;
}

.text-mute {
  color: #9b9b9b i !important;
}

.bg-blue {
  background-color: #2196f3 !important;
  color: #ffffff !important;
}

.text-green {
  color: #4caf50 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 5.75rem auto !important;
  }
  .middle-width {
    max-width: 750px !important;
  }
}

@media screen and (max-width: 768px) {
  .mobile-display-none {
    display: none;
  }
}
