/* @import "react-alice-carousel/lib/alice-carousel.css"; */
/* @import "react-alice-carousel/lib/scss/alice-carousel.scss"; */
/* Customize Modal */
.blank-page {
  display: flex;
  width: 100%;
  height: 50vh;
  align-items: center;
  justify-content: center;
}
.btn-custom {
  display: flex;
  font-weight: bold;
  font-size: 15px;
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #39ad44;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
}
.btn-custom:hover {
  opacity: 0.5;
}
.btn-clear {
  cursor: pointer;
}
.btn-clear:hover {
  opacity: 0.7;
}
.icon-middle {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 50px !important;
  background-color: #efefef;
  width: 50px !important;
  border-radius: 5px;
}
.icon-middle > .fa {
  font-size: 16px;
}
.clear {
  margin: 0 !important;
  padding: 0 !important;
}
.flex {
  display: flex;
  width: 0px !important;
  justify-items: flex-start;
  justify-content: flex-start !important;
}
.image-mini {
  height: 50px;
  width: auto;
  margin: 0px 10px;
}
.block {
  display: block;
}
.detail {
  color: #aeaeae;
  font-weight: bold;
  font-size: 13px;
}
.log-detail {
  margin: 10px 20px !important;
}
.modal-header {
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 20px;
}
.modal-header h5 {
  font-weight: 600;
}
.border-clear {
  border: 0 !important;
}
input[type="text"].border,
input[type="password"].border,
input[type="number"].border {
  border: 1px solid #ccc !important;
  padding: 10px 10px !important;
}

/* .modal-body { */
/* border-bottom: 1px solid #f3f3f3; */
/* } */
.body-header {
  display: flex;
  align-items: center;
  /* margin: 20px 0px; */
}
.body-header .row .col .fa {
  padding: 0px;
  margin: 10px;
  font-size: large;
  font-weight: 500;
  cursor: pointer;
}
.body-header .row .col .fa:hover {
  opacity: 0.5;
}
.header-menu > div > .fa {
  font-size: 20px;
  color: #000000;
  cursor: pointer;
}
.header-menu {
  display: flex;
  width: 100%;
  padding: 5px 0px;
  justify-content: flex-start;
}
.header-menu > .left {
  display: flex;
  width: 15%;
  justify-content: flex-start;
}
.header-menu > .right {
  display: flex;
  width: 15%;
  justify-content: flex-end;
}
.header-menu > .title {
  display: flex;
  width: 70%;
  justify-content: center;
}
.header-menu > .title > p {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: #323232;
}
.float-left {
  display: flex !important;
  justify-content: flex-start !important;
}
.body-footer {
  border-top: 1px solid #f3f3f3 !important;
  /* padding: 10px 10px !important; */
}
.form-control {
  border-radius: 5px !important;
  border: 2px solid #ccc !important;
  box-shadow: unset !important;
  margin-right: 5px !important;
  padding: 0px !important;
}
label,
/* input[type="checkbox"], */
/* input[type="radio"] {
  cursor: pointer;
} */
/* input[type="checkbox"], */
/* input[type="radio"] {
  padding: 8px !important;
  height: 5px !important;
  border-radius: 3px !important;
  width: 10px !important;
  min-height: 10px !important;
  min-width: 10px !important;
  box-shadow: unset;
  border: 1px solid #aeaeae !important;
  padding-bottom: 2px;
  padding-right: 2px;
  background-color: #fff !important;
} */

/* input[type="checkbox"]:checked::before, */
/* input[type="radio"]:checked::before {
  position: absolute;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1;
  left: 3.5px !important;
  top: -1px !important;
  font-weight: bold;
  content: "\02143";
  transform: rotate(40deg);
} */

/* input[type="checkbox"]:checked, */
/* input[type="radio"]:checked {
  background-color: #1d88e5 !important;
  color: #fff !important;
  border: 1px solid #1d88e5 !important;
} */

.body-row .row .col h3 {
  font-size: 13px !important;
  margin: 0px;
}
.black {
  color: #272727 !important;
}
.grey {
  color: #b1b1b1 !important;
}
.center {
  text-align: center !important;
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}
.link {
  color: #3ea2f4 !important;
  cursor: pointer !important;
}
input[type="text"].border,
/* input[type="password"].border, */
input[type="number"].border {
  border: 1px solid #ccc !important;
  padding: 10px 10px !important;
}

.border-bottom {
  border-bottom: 1px solid #f3f3f3;
}
.body-row {
  /* display: flex; */
  position: relative;
  flex: 1 1 auto;
  padding: 15px 15px;
  border-bottom: 1px solid #f3f3f3;
}
.body-row .row .col h3 {
  /* display: flex; */
  font-size: 16px;
  color: #2f2f2f;
  text-align: left;
}
.modal-footer {
  border-bottom: 0;
  margin-bottom: 20px;
  padding: 10px;
}
.body-row > .row > .value {
  font-size: 14px;
  font-weight: bold;
  color: #4e4e4e;
}
.body-row > .row > .title {
  font-size: 12px;
  font-weight: bold;
  color: #c1c1c1;
}
.check-all {
  display: flex;
  align-items: center;
  padding: 10px;
}
.check-all > span {
  font-weight: bold;
  color: #adadad;
}
.check-all > .fa {
  color: #1d88e5;
  margin-right: 10px;
  font-size: 20px;
}

.tenant-top {
  font-weight: bold;
  font-size: 14px;
  color: #332f46;
}
.tenent-bottom {
  color: #b9b9b9;
  font-size: 12px;
}
.avatar-image {
  width: 50px;
  height: auto;
  border-radius: 50%;
}
.img-report {
  width: 25%;
  height: auto;
  border-radius: 7px;
}
.modal-footer {
  padding: 0px;
}
.mini {
  top: 10% !important;
}

.text-note {
  /* padding: 0px 20px 20px 20px !important; */
  display: block;
  text-align: left;
  line-height: 14px;
  font-size: 10px;
}
.text-note > .row > .col {
  font-size: 12px;
  padding: 0px 15px;
  font-weight: 500;
}
.text-note > .row > .col > span {
  font-weight: bold;
  color: #6f6f6f !important;
}

.reset,
label,
.Mui-focused {
  font-size: 20px !important;
  /* color: #6f6f6f !important; disable for antdesign */
}

/* .reset, select {
    background: unset;
    border: unset !important;
    box-shadow: unset !important;
    margin-right: 0px !important;
    padding: 0px !important;
    margin-right: 0px !important;
    min-height: 35px !important;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
} */
.currency > div > input[type="number"] {
  text-align: right !important;
}
.reset {
  text-align: left;
}
/* .reset,
input[type="text"],
input[type="number"] {
  background: unset;
  border: unset !important;
  box-shadow: unset !important;
  margin-right: 0px !important;
  padding: 0px !important;
  margin-right: 0px !important;
  min-height: 35px !important;
  width: 100%;
  font-size: 15px;
  font-weight: 500;

} */
.reset,
label {
  font-size: 14px !important;
}
.reset,
.MuiFormControl-marginNormal {
  margin: 0px !important;
}
.reset,
.MuiFormControl-marginNormal {
  width: 100%;
}
/* .reset,
input[type="password"] {
  background: unset;
  border: unset !important;
  box-shadow: unset !important;
  margin-right: 0px !important;
  padding: 0px !important;
  margin-right: 0px !important;
  min-height: 35px !important;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
} */

.image-reset {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #777575;
  padding: 10px;
}
.image-reset > i {
  font-size: 22px;
  padding: 0;
  margin: 0;
  color: #fff;
}
.image-reset:hover {
  opacity: 0.6;
}
.image-edit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: #f44336; */
}
.image-edit > img {
  /* margin-top: 30px; */
  height: 150px;
  width: auto;
}
.image-edit > label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 45%;
  background-color: #777575;
  padding: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  opacity: 0.6;
}
.image-edit > label:hover {
  opacity: 1;
}
.image-edit > label > i {
  color: #ccc;
  font-size: 24px;
  padding: 0px;
  margin: 0px;
}
.image-edit > input {
  position: absolute;
  visibility: hidden;
}
.image-upload > .fa {
  /* position: absolute; */
  cursor: pointer;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
}
.image-upload > .fa:hover {
  opacity: 0.5;
}

.icon-only {
  display: flex;
  justify-content: left !important;
}
.icon-only > label {
  width: 0px !important;
}
.icon-only > label > i {
  font-size: 25px;
}
/* .reset, .MuiFormLabel-root {
    font-size: 13px;
} */

.MuiSelect-select.MuiSelect-select {
  margin-top: 20px;
}
.MuiSelect-outlined.MuiSelect-outlined.MuiSelect-select.MuiSelect-select {
  margin: 5px !important;
}
.MuiSelect-outlined.MuiSelect-outlined,
input[type="select"] {
  text-align: left;
}
textarea {
  box-shadow: unset !important;
}
.MuiOutlinedInput-input {
  padding: 5px 0px !important;
  font-size: 13px !important;
  color: #5a5a5a !important;
}
.MuiFormHelperText-root {
  color: red !important;
  font-size: 10px !important;
  margin: 0px !important;
}
.MuiFormControl-root {
  height: 75px !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid rgb(89, 87, 87) !important;
}
.MuiInput-underline:hover {
  /* border-bottom: 1px solid rgb(226, 19, 19) !important; */
  border: unset !important;
}
.is-invalid,
input[type="text"] {
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e); */
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.MuiInputLabel-shrink {
  transform: translate(0px, 0px) scale(1) !important;
}

.MuiFormControl-root {
  width: 100%;
}

.Mui-focused {
  min-height: 10px;
}
.MuiInputBase-input:focus {
  outline: 0 !important;
}
.MuiInputLabel-root {
  display: block;
  /* width: 100%; */
  text-align: center;
  font-size: 12px !important;
}
.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 15px) scale(1) !important;
  pointer-events: none;
}
.MuiInputLabel-shrink {
  display: block;
  align-items: center;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(1) !important;
}
.MuiOutlinedInput-root {
  padding: 3px 10px !important;
}
.MuiInputBase-root {
  /* padding: 3px 10px !important; */
}
/* .MuiOutlinedInput-input {
    padding: 3px 10px !important;
} */

.MuiOutlinedInput-root.Mui-focused {
  padding: 3px 10px !important;
}
.MuiFormHelperText-root.Mui-error {
  margin: 0px !important;
  font-size: 10px !important;
}
.MuiFormHelperText-root.Mui-error.Mui-focused {
  font-size: 10px !important;
}
.MuiInputLabel-shrink {
  font-weight: bold !important;
  font-size: 14px !important;
}
/* Loader style */
.loader {
  border: 16px solid #dde1b5;
  border-radius: 50%;
  border-top: 16px solid #bac62a;
  border-bottom: 16px solid #bac62a;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.small {
  border: 10px solid #dde1b5;
  border-top: 10px solid #bac62a;
  border-bottom: 10px solid #bac62a;
  width: 50px;
  height: 50px;
}
/* Card List */

.list-card {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: flex-start;
  padding: 20px;
}
.card {
  flex-basis: 250px;
  max-width: 450px;
  height: 350px;
  /* margin: 5px 5px; */
  display: grid;
  width: 250px;
  box-shadow: 2px 2px 2px 2px #eaeaea;
  background-color: #fff;
  border-radius: 7px;
  margin: 5px;
  padding: 20px;
}

.card-middle {
  flex-basis: 100%;
  max-width: 100%;
  height: 350px;
  display: block;
  width: 250px;
  box-shadow: 2px 2px 2px 2px #eaeaea;
  background-color: #fff;
  border-radius: 7px;
  margin-bottom: 20px;
}

.card-logo {
  display: flex;
  align-items: center;
  height: 70px;
  width: auto;
  justify-content: center;
  margin-bottom: 20px;
}

.card-logo-text {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  justify-content: flex-start;
  border-bottom: 1px solid #dadada;
  padding: 0px 20px;
}

.card-logo-text > div > span {
  font-size: 14px;
  font-weight: bold;
  color: #454545;
}
.card-logo-text > span {
  color: #454545;
  font-weight: 550;
  font-size: 13px;
}
.card-logo-text > div > img {
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.card-content {
  padding: 0px 20px;
}
.list-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: flex-start;
  border-bottom: 1px solid #dadada;
  /* padding: 0px 20px; */
}
.list-item > span {
  font-weight: bold;
}

.list-item > img {
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 5px;
}

.list-right {
  display: flex;
  justify-content: flex-end;
}

.list-right > span {
  font-weight: bold;
  color: #54b257;
}

.list-bottom {
  border: 0 !important;
  display: flex !important;
  align-items: flex-end !important;
  margin: 0;
  padding: 0;
}
.list-bottom > div {
  margin: 0;
  padding: 0;
}

.list-item-text {
  display: block !important;
  margin-left: 10px;
}

.list-item-text > .item-name > span {
  font-weight: bold;
  color: #454545;
}
.list-item-text > .item-qty > span {
  font-weight: bold;
  font-size: 12px;
}

.list-item > div > div > .view {
  font-size: 12px;
  font-weight: bold;
}

.list-item > div > div > .fa {
  font-size: 20px;
  margin-right: 10px;
}

.list-item > div > div {
  display: flex;
  width: fit-content;
  align-items: center;
  /* cursor: pointer; */
}

.list-item > div > div:hover {
  /* opacity: .6; */
}

.card-logo > .fa {
  font-size: 34px;
  color: #7dd9e5;
}
.card-head > .title > span {
  font-weight: bold;
  color: #454545;
  font-size: 13px;
  margin: 0px;
  padding: 0px;
}
.card-head,
.addrress {
  height: 70px !important;
  overflow: hidden;
  font-size: 12px;
}
.card-center {
  display: grid;
  width: 100%;
  padding: 5px 0px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin: 10px 0px;
}
.card-center > div {
  display: flex;
  height: 100%;
  padding: 5px 0px;
  align-items: center;
}
.card-center > div > span > i {
  font-size: 20px;
}
.card-bottom {
  display: flex;
  align-items: flex-end;
}
.card-bottom > div {
  width: 50%;
  margin: 0px 5px;
}
.table-form {
  height: 200px;
  overflow: auto;
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
  /* box-shadow: -2px 2px 2px 5px #ccc; */
  /* padding-bottom: 5px; */
  /* background-color: #efefef; */
  padding: 0;
}

/* Table List */
.table-responsive-sm > table {
  margin-bottom: 0px !important;
}
.table {
  background-color: #fff;
}
.table > thead {
  background: #efefef;
}

.table > thead.thead-primary {
  background: #4baf50 !important;
}
/* thead[class*="ant-table-thead"] > tr > th.ant-table-cell{
  background-color: #4baf50 !important;
  color:#fff;
} */
/* .ant-btn-primary {
  background-color: #4baf50 !important;
  color:#fff !important;
  border-color: #4baf50 !important;
} */

.table > thead.thead-primary > tr > th {
  color: #f3f3f3 !important;
}

.table > thead > tr > th {
  color: #222;
  font-weight: bold;
  font-size: 13px;
}
.table td {
  vertical-align: middle !important;
}
/* .table > tbody {
    padding: 10px 15px 25px 15px;
    background-color: #cccc;
    border-top: 0px;
    height: calc(100vh - 350px);
    overflow: auto;
} */
.table > tbody > tr > td {
  font-size: 13px;
}
.table > tbody > tr > td > img {
  padding: 5px 10px;
  max-height: 40px;
  width: auto;
}
.table > tbody > tr > td > .fa {
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
}
.table > tbody > tr > td > .fa:hover {
  opacity: 0.5;
}
.c1 {
  width: 10px;
}
.image-wrapper {
  margin: 10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
}
.bold {
  font-weight: bold;
}
.margin {
  margin-bottom: 10px !important;
}

.MuiInput-underline {
  border-bottom: 0;
}
.MuiOutlinedInput-inputMultiline {
  min-height: 30px;
}

/*Tooltip*/
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Progress stepper */
.progress-stepper {
  display: block;
}
.progress-stepper > h3 {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #6e6e6e;
}
.progress-container {
  display: flex;
  justify-content: space-between;
  margin-top: -16px;
}
.progress-container > .wrapp-box {
  padding: 5px;
  width: 100px;
  display: block;
}
.progress-container > .wrapp-box > .box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.progress-container > .wrapp-box > .box > .node {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.progress-container > .wrapp-box > .box > .node:hover {
  border: 4px solid rgb(221, 221, 221);
}
.progress-line {
  margin-top: 15px;
  margin-left: 50px;
  margin-right: 50px;
  border: 1px solid #cacaca;
}
.default-color {
  background-color: rgb(148, 148, 148);
  border: 3px solid rgb(220, 220, 220);
}
.wait-color {
  background-color: tomato;
  border: 3px solid rgb(247, 148, 130);
}

.process-color {
  background-color: tomato;
  border: 3px solid rgb(247, 148, 130);
}

.complete-color {
  background-color: tomato;
  border: 3px solid rgb(247, 148, 130);
}
.ispected-color {
  background-color: tomato;
  border: 3px solid rgb(247, 148, 130);
}
.approved-color {
  background-color: tomato;
  border: 3px solid rgb(247, 148, 130);
}
.unassigned {
  background-color: #f44336;
  border: 3px solid #f5a29c;
}
.wait {
  background-color: #f44336;
  border: 3px solid #f5a29c;
}
.progress {
  background-color: #ff9800;
  border: 3px solid #fcd293;
}
.process {
  background-color: #ff9800;
  border: 3px solid #fcd293;
}
.complete {
  background-color: #4caf50;
  border: 3px solid #c0f5c2;
}
.inspect {
  background-color: #fcf404;
  border: 3px solid #ebec87;
}
.approval {
  background-color: #00bcd4;
  border: 3px solid #b0f0f8;
}
.approve {
  background-color: #00bcd4;
  border: 3px solid #b0f0f8;
}
.approved {
  background-color: #00bcd4;
  border: 3px solid #b0f0f8;
}
.disable {
  background-color: #9b9b9b !important;
  border: 3px solid #e2e2e2 !important;
}
/* Carousel */
.alice-carousel__prev-btn {
  text-align: left !important;
  position: absolute;
  left: 0;
  top: 40%;
}
.alice-carousel__prev-btn-item {
  margin-left: -40px;
}
.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #ccc;
  height: 50px;
  font-size: 30px;
}
.alice-carousel__prev-btn-item {
  margin-left: -40px !important;
}
.alice-carousel__next-btn-item {
  margin-right: -40px !important;
}
.alice-carousel__next-btn {
  text-align: right !important;
  position: absolute;
  right: 0;
  top: 40%;
}
.alice-carousel__stage-item {
  margin: 5px !important;
}
.alice-carousel__dots {
  display: none;
}
/*
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    display: inline-block;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    color: #ccc;
    height: 50px;
    font-size: 30px;
    margin-left: -40px;
} */

/* Units modal select */

.align-right {
  display: flex;
  justify-content: flex-end !important;
}
.align-left {
  display: flex;
  justify-content: flex-start !important;
}
.title {
  font-weight: bold;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}
.list-wrapper {
  display: flex;
  padding: 0px 10px;
}
.list-wrapper > .item {
  padding: 10px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
}
.preloader .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font: 14px arial;
}
